import {ref, reactive, toRefs, onMounted, defineComponent, getCurrentInstance, computed} from 'vue';
import UtilPub from "@/utils/UtilPub";

export default defineComponent({
    name: "WaitWorkList",
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;

        let dataObj=reactive({
            corpTreeData: [],
            billTypeTreeData:[],
            pageListRef: {} as any,
            canRollBack:false,
            pageList: {
                queryParam: {},
                canPage:false,
                multiple:true,
                modelMethod: utils.Api.buildUrl("/waitWork/pageData")
            },
            submitData:{
                id:'',
                stepData:''
            },
            rollBackType:'',
            rollbackDialogLoading:false,
            rollbackDialogVisible:false
        })
        onMounted(async ()=>{
            dataObj.corpTreeData = await utils.Api.corpTreeData();
            let res = await utils.Api.billTypeTreeData();
            if(res.result)dataObj.billTypeTreeData=JSON.parse(res.billTypeData);
        })

        //驳回单据
        const rollbackHandler=(row:any)=>{
            dataObj.pageListRef.getTbInst().toggleRowSelection(row);
            dataObj.rollbackDialogVisible=true;
            dataObj.rollBackType='single';
            dataObj.submitData.id=row['F_BILL_ID'];
        }
        //批量驳回
        const batchRollBackHandler=()=>{
            let selectRows = dataObj.pageListRef.getTbInst().getSelection();
            if (selectRows.length == 0) {
                utils.Tools.info({message: "请选择要驳回的单据"});
                return;
            }

            dataObj.rollbackDialogVisible=true;
            dataObj.rollBackType='batch';
            let ids = "";
            selectRows.forEach((item:any)=>{
                ids = ids + item['F_BILL_ID'] + ",";
            });
            ids = ids.trim().substr(0, ids.trim().length - 1);//去掉最后的逗号
            dataObj.submitData.id=ids;
        }
        //向后台发送驳回请求
        const rollbackDialogSure=async()=>{
            let res;
            if(dataObj.rollBackType=='single'){
                res = await utils.Api.postRequest({url: '/waitWork/rollBack', params: dataObj.submitData});
            }else{
                res = await utils.Api.batchRollBack(dataObj.submitData);
            }
            if(res.result){
                dataObj.pageListRef.queryHandler();
                utils.Tools.success({message: res.msg});
                dataObj.rollbackDialogVisible=false;
            }
        }
        //查看单据
        const detailHandler=async (row:any)=>{
            dataObj.pageListRef.getTbInst().toggleRowSelection(row);
            let options= {
                addOrEdit:'edit',
                id:row['F_BILL_ID'],
                proxy:proxy,
                modelComp: await utils.BillComponent.getComponentByBillType({modelId:row['F_MODEL_ID']}),
                // ownerComp:dataObj.pageListRef,
                ownerComp:proxy,
                contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true
            };
            await utils.UtilPub.openDialog(options)
        }

        //表格加载完毕事件
        const gridLoaded=(res:any)=>{
            dataObj.canRollBack=res.canRollBack;
        }
        //关闭弹出框（审核单据详情），需要重新加载列表
        const cancelHandler=(inst:any)=>{
            dataObj.pageListRef.queryHandler(true)
        }
        return{
            ...toRefs(dataObj),rollbackHandler,batchRollBackHandler,rollbackDialogSure,detailHandler,gridLoaded,cancelHandler
        }
    }
});